export const getHeaders = (
  headers: Headers | undefined,
  token?: string
): Headers => {
  if (headers) {
    headers.set("Authorization", `Bearer ${token}`);
    return headers;
  } else if (token) {
    return new Headers({ Authorization: `Bearer ${token}` });
  } else {
    return new Headers({});
  }
};
