import { useQueryClient } from "@tanstack/react-query";

import { Case } from "./caseTypes";
import queryKeys from "../../utils/queryKeys";
import { getReports } from "../reports/reportsGetApi";
import { getReportsOptions } from "../reports/reportsOptionsGetApi";
import { getCaseFiles } from "../sources/filesGetApi";

/**
 * When loading a case for the first time, there is a high probability
 * that transactions, sources, and reports (exhibits) will be loaded.
 * Let's enhance the user experience and query for these right away,
 * such that they will immediately be available in the UI for the end user.
 * @returns void
 */
const usePrefetchCaseData = () => {
  const queryClient = useQueryClient();
  return (caseId: Case["id"]) => {
    // Prefetch the list of report options
    void queryClient.prefetchQuery({
      queryKey: queryKeys.getReportsOptionsKey(),
      queryFn: () => getReportsOptions({ caseId }),
    });

    // Prefetch any reports
    void queryClient.prefetchQuery({
      queryKey: queryKeys.getReportsKey(caseId),
      queryFn: () => getReports({ caseId }),
    });

    // Prefetch any sources
    void queryClient.prefetchQuery({
      queryKey: queryKeys.getFilesKey(caseId),
      queryFn: () =>
        getCaseFiles({
          queryClient,
          caseId,
        }),
    });
  };
};

export default usePrefetchCaseData;
