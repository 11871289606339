import { z } from "zod";

export enum CaseStatus {
  IDLE = "IDLE",
  FILE_INGESTION_IN_PROGRESS = "FILE_INGESTION_IN_PROGRESS",
  TAX_ENGINE_IN_PROGRESS = "TAX_ENGINE_IN_PROGRESS",
  TRANSFER_DETECTION_IN_PROGRESS = "TRANSFER_DETECTION_IN_PROGRESS",
  TRANSFER_DETECTION_FAILED = "TRANSFER_DETECTION_FAILED",
  TAX_ENGINE_FAILED = "TAX_ENGINE_FAILED",
  // To be removed once TSS is no longer used to trigger tax calcs
  TAX_ENGINE_SCHEDULED = "TAX_ENGINE_SCHEDULED",
}

export const caseStatusSchema = z.object({
  caseStatus: z.union([z.nativeEnum(CaseStatus), z.string()]),
});

export type CaseStatusEnum = z.infer<typeof caseStatusSchema>;
