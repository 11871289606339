import { useQuery } from "@tanstack/react-query";

import { getReportsOptionsSchema } from "./reportsOptionsGetApiTypes";
import { getBaseReportUrl } from "../../../utils/environment";
import { makeApiRequest } from "../../useMakeRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";
import { Case } from "../cases/caseTypes";

type getReportsOptionsParams = {
  caseId: Case["id"];
};

export const getReportsOptions = async ({
  caseId,
}: getReportsOptionsParams) => {
  const baseUrl = getBaseReportUrl();

  return makeApiRequest({
    baseUrl,
    urlPath: pathBuilders.buildReportsOptionsPath(caseId),
    responseDataSchema: getReportsOptionsSchema,
  });
};

export const useGetReportsOptions = (caseId: Case["id"]) => {
  return useQuery({
    queryKey: queryKeys.getReportsOptionsKey(),
    queryFn: () => getReportsOptions({ caseId }),
  });
};
