import { Case } from "../endpoints/cases/caseTypes";
import { Report } from "../endpoints/reports/reportsGetApiTypes";
import { GetFilesRequest } from "../endpoints/sources/fileGetApiTypes";
import { GetTransactionsRequest } from "../endpoints/transactions/transactionsGetApiTypes";

type CaseId = Case["id"];
type TransactionId = string;

enum Key {
  AccountingMethods = "accounting-methods",
  Assets = "assets",
  Cases = "cases",
  CalcStatus = "calc-status",
  CostBases = "cost-bases",
  Transactions = "transactions",
  TransactionStats = "transactions/stats",
  Users = "users",
  Reports = "reports",
  ReportsOptions = "reports/options",
  IntegrationTemplates = "integration/templates",
  IntegrationAccountSources = "integration/sources",
  AssetTemplates = "assets/search",
  FileValidateKey = "files/validate",
  Files = "files",
  File = "files/file_id",
  FileErrors = "files/file_id/errors",
  FileUploadUrl = "files/upload-url",
  Tenant = "tenant",
}

const queryKeys = {
  getAccountingMethodKey: () => [Key.AccountingMethods] as const,
  getAssetKey: (assetId: string) => [Key.Assets, { uuid: assetId }],
  getCasesKey: () => [Key.Cases] as const,
  getCaseKey: (caseId: CaseId) => [Key.Cases, { caseId }],
  getCaseSummaryKey: (caseId: CaseId, startDate: string, endDate: string) => [
    Key.Cases,
    { caseId, startDate, endDate },
  ],
  getCaseStatusKey: (caseId: CaseId) => [Key.CalcStatus, { caseId }],
  getCostBasesKey: (caseId: CaseId, transactionId: TransactionId) => [
    Key.CostBases,
    { caseId, transactionId },
  ],
  getReportsKey: (caseId: CaseId) => [Key.Reports, { caseId }] as const,
  getReportKey: (caseId: CaseId, createdDatetime: Report["createdDatetime"]) =>
    [Key.Reports, { caseId, createdDatetime }] as const,
  getReportsOptionsKey: () => [Key.ReportsOptions] as const,
  getIntegrationTemplatesKey: () => [Key.IntegrationTemplates] as const,
  getIntegrationAccountSources: () => [Key.IntegrationAccountSources] as const,
  getAssetTemplatesKey: (code: string | undefined) => [
    Key.AssetTemplates,
    { code },
  ],
  getTransactionsKey: (
    caseId: CaseId,
    requestParams?: GetTransactionsRequest
  ) =>
    [
      Key.Transactions,
      { caseId },
      { ...(requestParams && { requestParams }) },
    ] as const,
  getTransactionKey: (caseId: CaseId, transactionId: TransactionId) =>
    [Key.Transactions, { caseId }, { transactionId }] as const,
  getTransactionsStatsKey: (
    caseId: CaseId,
    requestParams?: GetTransactionsRequest
  ) =>
    [
      Key.TransactionStats,
      { caseId },
      { ...(requestParams && { requestParams }) },
    ] as const,
  getUsersKey: () => [Key.Users] as const,
  getFileUploadKey: (caseId: CaseId) => [Key.FileUploadUrl, { caseId }],
  getFilesKey: (caseId: CaseId, requestParams?: GetFilesRequest) =>
    [
      Key.Files,
      { caseId },
      { ...(requestParams && { requestParams }) },
    ] as const,
  getFileKey: (caseId: CaseId, jobId: string) =>
    [Key.File, { caseId, jobId }] as const,
  getFileErrorsKey: (caseId: CaseId, jobId: string) =>
    [Key.FileErrors, { caseId, jobId }] as const,
  getTenantKey: () => [Key.Tenant] as const,
};

export default queryKeys;
