import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const SOURCE_LABEL_REGEX = /^[a-zA-Z0-9 _-]+$/;

const subpoenaSchema = z.object({
  label: z.string(),
  subpoena_type: z.string(),
});

export type AddSubpoenaForm = z.infer<typeof subpoenaSchema>;

export enum FileRequestSourceType {
  SUBPOENA_INTERNAL = "subpoena-internal",
  JSONL = "jsonl",
  FORCED_MATCHING = "forced-matching",
  WALLET = "wallet",
}

export const useAlphanumericStringSchema = () => {
  const { t } = useTranslation("sources");

  return useMemo(
    () =>
      z
        .string()
        .trim()
        .min(1, t("AccountNameRequiredMsg"))
        .regex(SOURCE_LABEL_REGEX, t("ValidAccountNameMsg")),
    [t]
  );
};

export const useAddSubpoenaFileRequestSchema = () => {
  const alphanumericStringSchema = useAlphanumericStringSchema();

  return useMemo(
    () =>
      z.object({
        fileName: z.string().optional(),
        source: z.string().optional(),
        sourceType: z.nativeEnum(FileRequestSourceType),
        subpoenaType: z.string().optional(),
        label: alphanumericStringSchema.optional(),
        integrationId: z.string().optional(),
      }),
    [alphanumericStringSchema]
  );
};

export type AddSubpoenaFileRequest = z.infer<
  ReturnType<typeof useAddSubpoenaFileRequestSchema>
>;

export type SubSource = {
  name: string;
  type: string;
};

export const useGenericCsvSchema = () => {
  const alphanumericString = useAlphanumericStringSchema();

  return useMemo(
    () =>
      z.object({
        label: alphanumericString,
      }),
    [alphanumericString]
  );
};

export type AddGenericCsvForm = z.infer<ReturnType<typeof useGenericCsvSchema>>;

export const useJsonLFileSchema = () => {
  const alphanumericString = useAlphanumericStringSchema();

  return useMemo(
    () =>
      z.object({
        label: alphanumericString,
        walletAddress: z.string().optional(),
      }),
    [alphanumericString]
  );
};

export const usePublicAddressSchema = () => {
  const alphanumericString = useAlphanumericStringSchema();

  return useMemo(
    () =>
      z.object({
        walletAddress: z.string().trim(),
        source: z.string(),
        label: alphanumericString,
        integrationId: z.string(),
        sourceType: z.nativeEnum(FileRequestSourceType),
      }),
    [alphanumericString]
  );
};

export type AddJsonLFileSchemaForm = z.infer<
  ReturnType<typeof useJsonLFileSchema>
>;

export type AddPublicAddressSchemaForm = z.infer<
  ReturnType<typeof usePublicAddressSchema>
>;
