import { z } from "zod";

const reportsOptionSchema = z.object({
  name: z.string(),
  label: z.string(),
});
export type ReportsOptionSchema = z.infer<typeof reportsOptionSchema>;

export const getReportsOptionsSchema = z.array(reportsOptionSchema);
export type ReportsOptionsSchema = z.infer<typeof getReportsOptionsSchema>;
