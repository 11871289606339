import { z, ZodError } from "zod";

const projectErrorResponseSchema = z.object({
  errorCode: z.string(),
  errorInput: z.string().optional(),
  validInputs: z.array(z.string()).optional(),
});

export type ProjectErrorResponse = z.infer<typeof projectErrorResponseSchema>;

const errorResponseSchema = z.object({
  success: z.literal(false),
  errorMessage: z.string(),
  errors: z.array(projectErrorResponseSchema),
});

type ErrorResponse = z.infer<typeof errorResponseSchema>;

/**
 * A helper that will successfully throw an error message as returned from the backend.
 * The standardized error response is something like this:
 *
 * {
 *   success: false
 *   error: {
 *     errorCode: "invalid_input"
 *     errorInput: "foo"
 *     validInputs: ["bar", "baz"]
 *   }
 * }
 *
 * This typeguard will help validate that the returned 400 response matches this expected type
 * There are a few "any" ignores here, which are safe in this scenario
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isErrorResponse(response: any): response is ErrorResponse {
  try {
    // Validate the response against the schema
    errorResponseSchema.parse(response);
    return true;
  } catch (error) {
    if (error instanceof ZodError) {
      return false;
    }
    throw error;
  }
}

export default isErrorResponse;
