import { Flex, Banner, InlineSpinner } from "@taxbit-private/cosmic";
import { useParams } from "react-router-dom";

import useDartsStore from "../../store/useDartsStore";

const BannerNotification: React.FC = () => {
  const { caseId } = useParams();
  const banners = useDartsStore((state) => state.banners);

  if (!caseId) {
    return <></>;
  }
  const banner = banners[caseId];

  return banner ? (
    <Flex growItems={true}>
      <Banner variant="primary" trackingId="add-src-notification-banner">
        <Flex alignItems="center" gap="s">
          {banner.shouldShowSpinner && <InlineSpinner />}
          {banner.message}
        </Flex>
      </Banner>
    </Flex>
  ) : (
    <></>
  );
};

export default BannerNotification;
