export const getBaseCaseApiUrl = (): string => {
  return import.meta.env.VITE_REACT_APP_BASE_CASE_API_URL;
};

export const getBaseReportUrl = (): string => {
  return import.meta.env.VITE_REACT_APP_BASE_EXHIBITS_API_URL;
};

export const isGovCloudEnvironment = (): boolean => {
  return Boolean(import.meta.env.VITE_REACT_APP_LOGIN_GOV_CLIENT_ID);
};

export const isCommercialEnvironment = (): boolean => {
  return !isGovCloudEnvironment();
};

export const isProductionEnvironment = (): boolean => {
  return Boolean(import.meta.env.VITE_REACT_APP_ENVIRONMENT === "prod");
};
