import { ProjectErrorResponse } from "../api/utils/handleCustomErrorResponse";

export class ProjectError extends Error {
  errors: ProjectErrorResponse[];

  statusCode: number;

  constructor({
    message,
    statusCode,
    errors,
  }: {
    message: string;
    statusCode: number;
    errors: ProjectErrorResponse[];
  }) {
    super();
    this.message = message;
    this.statusCode = statusCode;
    this.errors = errors;
  }
}
