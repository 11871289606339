import { z } from "zod";

export enum ImputationMethodEnum {
  TAXABLE = "taxable-set-as-income-or-expense",
  NON_TAXABLE = "non-taxable-keep-as-transfer",
}

const imputationMethodSchema = z.nativeEnum(ImputationMethodEnum);

export const customTxnRulesSchema = z.object({
  imputation: imputationMethodSchema,
});

const getImputationMethodsSchema = z.array(
  z.object({
    id: z.string().transform((id) => id as ImputationMethodEnum),
    label: z.string(),
  })
);

export type GetImputationMethodsResponse = z.infer<
  typeof getImputationMethodsSchema
>;
