import useTenantIdDoesMatch from "./useTenantIdDoesMatch";

const DARTS_CI_TENANT_IDS = new Set([
  "tenant_89eb8ae4-0a5e-4b3b-b5e3-2c27b775e5e5",
]);

/**
 * This hook determines whether the current tenant id is included in the set of CI tenant ids
 * @returns A boolean indicating whether the current tenant is a CI tenant
 */
export const useIsCiTenant = () => useTenantIdDoesMatch(DARTS_CI_TENANT_IDS);
