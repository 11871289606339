import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import useLocalStorageState from "./useLocalStorageState";
import useRequiredParams from "./useRequiredParams";
import { useGetCase } from "../../api/endpoints/cases/caseGetApi";
import { caseRouteParamsSchema } from "../../pages/case-default/CaseIndexPage";
import useDartsStore from "../../store/useDartsStore";

function useFailedTransferDetectionToast() {
  // Using state here is causing the toast to show duplicates when navigating to a different case and
  // then back to the original case. Using a ref instead so the toast will only show once per failed transfer detection.
  const isTransferDetectionFailedToastShown = useRef<boolean>(false);

  const { caseId } = useRequiredParams(caseRouteParamsSchema);
  const { data: caseData } = useGetCase(caseId);
  const { t } = useTranslation("case");
  const { formatDateTime } = useCosmicLocalizationContext();
  const addToast = useDartsStore((state) => state.addToast);

  const [
    transferDetectionFailedToastDismissed,
    setTransferDetectionFailedToastDismissed,
  ] = useLocalStorageState<string[]>(
    "tb.darts.transferDetectionFailedToastDismissed",
    []
  );

  useEffect(() => {
    if (
      caseData?.transferDetectionFailedTimestamp &&
      !isTransferDetectionFailedToastShown.current
    ) {
      const failedKey = `${caseData.id}_${caseData.transferDetectionFailedTimestamp}`;

      if (!transferDetectionFailedToastDismissed.includes(failedKey)) {
        isTransferDetectionFailedToastShown.current = true;

        addToast({
          content: t("LastTransferDetectionFailed", {
            timestamp: formatDateTime({
              date: caseData.transferDetectionFailedTimestamp,
              format: "DateTime",
            }),
          }),
          variant: "danger",
          autoHide: false,
          onClose: () => {
            setTransferDetectionFailedToastDismissed([
              ...transferDetectionFailedToastDismissed,
              failedKey,
            ]);
            isTransferDetectionFailedToastShown.current = false;
          },
        });
      }
    }
  }, [
    caseData,
    addToast,
    transferDetectionFailedToastDismissed,
    setTransferDetectionFailedToastDismissed,
    formatDateTime,
    t,
  ]);
}

export default useFailedTransferDetectionToast;
