import ToastNotifications from "./ToastNotifications";

/**
 * All components that contain top-level notifications should be surfaced in this
 * component. This component will appear on top of all app contents.
 */
const AppNotifications: React.FC = () => {
  return <ToastNotifications />;
};

export default AppNotifications;
