export enum HttpErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

class HttpError extends Error {
  public statusCode: HttpErrorCode;

  public message: string;

  constructor(statusCode: HttpErrorCode, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
  }
}

export default HttpError;
