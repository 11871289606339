import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import usePrefetchCaseData from "../../api/endpoints/cases/usePrefetchCaseData";
import { useFlags } from "../../utils/hooks/useFlags";
import useRequiredParams from "../../utils/hooks/useRequiredParams";

export const caseRouteParamsSchema = z.object({
  caseId: z.string(),
});

const CaseDefaultPage: React.FC = () => {
  const prefetchCaseData = usePrefetchCaseData();
  const { caseId } = useRequiredParams(caseRouteParamsSchema);
  const { isCaseSummaryEnabled } = useFlags();

  useEffect(() => {
    if (caseId) {
      prefetchCaseData(caseId);
    }
  }, [caseId, prefetchCaseData]);

  return (
    <Navigate to={isCaseSummaryEnabled ? "case-summary" : "transactions"} />
  );
};

export default CaseDefaultPage;
