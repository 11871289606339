import { z } from "zod";

/*
 * Unfortunately the regex below doesn't reject a UUID with an invalid version
 * number; found within the first character of the third group. This is because
 * we've ingested UUIDs which were created sequentially, that is:
 * 00000000-0000-0000-0000-000000000001 ...
 */
export const uuidSchema = z
  .string()
  .regex(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  );
