import { useParams } from "react-router-dom";
import * as z from "zod";

function useRequiredParams<T extends Record<string, z.ZodString>>(
  schema: z.ZodObject<T>
): z.infer<z.ZodObject<T>> {
  const params = useParams<Record<keyof T, string>>();
  return schema.parse(params);
}

export default useRequiredParams;
