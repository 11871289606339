import { Auth } from "@aws-amplify/auth";
import { jwtDecode, JwtPayload } from "jwt-decode";

// Using Auth.currentSession() will auto refresh an expired
// access token with the provided refresh token, preventing
// users from having API access revoked after the access token
// expiration. When the refresh token expires (usually longer),
// then the user will need to re-authenticate
export const getAccessToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

type AccessTokenType = {
  "cognito:groups": string[];
  email: string;
  "cognito:username": string;
} & JwtPayload;

type CurrentUserType = {
  sub?: string;
  email: string;
  username: string;
};

export const getCurrentTenantId = async () => {
  const accessToken = await getAccessToken();
  const decodedToken = jwtDecode(accessToken);
  const cognitoGroups = (decodedToken as AccessTokenType)["cognito:groups"];
  const foundTenantIds = cognitoGroups.filter((group: string) =>
    group.startsWith("tenant_")
  );
  if (foundTenantIds.length > 1) {
    throw new Error("User belongs to multiple tenants");
  }
  return foundTenantIds[0];
};

export const getCurrentUserFields = async (): Promise<CurrentUserType> => {
  const accessToken = await getAccessToken();
  const decodedToken = jwtDecode(accessToken);
  const {
    sub,
    email,
    "cognito:username": username,
  } = decodedToken as AccessTokenType;
  return { sub, email, username };
};
