import { z } from "zod";

import zodLaxEnum from "../../../types/zodEnum";
import { CaseId, CaseName } from "../cases/caseTypes";

export enum ReportStatus {
  INITIALIZED = "INITIALIZED",
  IN_PROCESS = "IN PROGRESS",
  FAILED = "FAILED",
  COMPLETE = "COMPLETE",
}

const reportStatusSchema = zodLaxEnum([
  ReportStatus.INITIALIZED,
  ReportStatus.IN_PROCESS,
  ReportStatus.FAILED,
  ReportStatus.COMPLETE,
]);

export const reportSchema = z.object({
  caseId: z.string().transform((id) => id as CaseId),
  caseName: z.string().transform((name) => name as CaseName),
  exhibitType: z.string(),
  exhibitDisplayName: z.string(),
  paramStartDate: z.string().datetime(),
  paramEndDate: z.string().datetime(),
  createdDatetime: z.string().datetime(),
  updatedDatetime: z.string().datetime(),
  status: reportStatusSchema,
  signedUrl: z.string().url().optional(),
});

export type Report = z.infer<typeof reportSchema>;

export const getReportsSchema = z.array(reportSchema);
export type getReportsResponse = z.infer<typeof getReportsSchema>;
