import { Case } from "../endpoints/cases/caseTypes";
import { Report } from "../endpoints/reports/reportsGetApiTypes";
import {
  FileUpload,
  GetFilesRequest,
} from "../endpoints/sources/fileGetApiTypes";

type CaseId = Case["id"];
type TransactionId = string;

/**
 * Standardized helpers to build query paths used in the DARTS repo. This
 * allows for easy synchronization between the real DARTS endpoints and mock service
 * worker handlers intended to provide mocks for them.
 */
const pathBuilders = {
  buildAccountingMethodsPath: () => "accounting-methods" as const,
  buildAssetPath: (assetId: string) => `assets/${assetId}` as const,
  buildCasesPath: () => "cases" as const,
  buildCasePath: (caseId: CaseId) => `cases/${caseId}` as const,
  buildCaseSummaryPath: (caseId: CaseId) => `cases/${caseId}/summary` as const,
  buildCaseStatusPath: (caseId: CaseId) =>
    `cases/${caseId}/case-status` as const,
  buildCostBasesPath: (caseId: CaseId, transactionId: TransactionId) =>
    `cases/${caseId}/transactions/${transactionId}/cost-bases` as const,
  buildReportsPath: (caseId: CaseId) => `cases/${caseId}/exhibits` as const,
  buildReportPath: (
    caseId: CaseId,
    createdDatetime: Report["createdDatetime"]
  ) => `cases/${caseId}/exhibits/${createdDatetime}` as const,
  buildReportsOptionsPath: (caseId: CaseId) =>
    `cases/${caseId}/exhibits/options` as const,
  buildIntegrationTemplatesPath: () => "integrations/templates" as const,
  buildIntegrationAccountSourcesPath: () => "integrations/sources" as const,
  buildAssetTemplatesPath: () => "assets/search" as const,
  buildSingleTransactionPath: (caseId: CaseId, transactionId: TransactionId) =>
    `cases/${caseId}/transaction/${transactionId}` as const,
  buildTransactionsPath: (caseId: CaseId) =>
    `cases/${caseId}/transactions` as const,
  buildTransactionPath: (caseId: CaseId, transactionId: TransactionId) =>
    `cases/${caseId}/transactions/${transactionId}` as const,
  buildTransactionsStatsPath: (caseId: CaseId) =>
    `cases/${caseId}/transactions/stats` as const,
  buildUsersPath: () => "users" as const,
  buildFileUploadPath: (caseId: CaseId) =>
    `cases/${caseId}/files/upload-url` as const,
  buildWalletUploadPath: (caseId: CaseId) => `cases/${caseId}/wallet` as const,
  buildWalletsUploadPath: (caseId: CaseId) =>
    `cases/${caseId}/wallets` as const,
  buildFilesPath: (caseId: CaseId, requestParams?: GetFilesRequest) => {
    if (!requestParams) {
      return `cases/${caseId}/files`;
    }
    const queryParams = Object.entries(requestParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return `cases/${caseId}/files?${queryParams}`;
  },
  buildFilePath: (caseId: CaseId, jobId: FileUpload["jobId"]) =>
    `cases/${caseId}/files/${jobId}` as const,
  buildFileErrorsPath: (caseId: CaseId, jobId: FileUpload["jobId"]) =>
    `cases/${caseId}/files/${jobId}/errors` as const,
  buildMultipartUploadPath: (caseId: CaseId) =>
    `cases/${caseId}/files/s3-multipart` as const,
  buildMultipartUploadItemPath: (caseId: CaseId, multipartUpload: string) =>
    `cases/${caseId}/files/s3-multipart/${multipartUpload}` as const,
  buildMultipartUploadPathComplete: (
    caseId: CaseId,
    multipartFileUploadId: string
  ) =>
    `cases/${caseId}/files/s3-multipart/${multipartFileUploadId}/complete` as const,
  buildFilesUploadPath: (caseId: CaseId) =>
    `cases/${caseId}/files/upload` as const,
  buildFileValidatePath: (caseId: CaseId) => `cases/${caseId}/files/validate`,
  buildTenantMetadataPath: () => "tenant-metadata" as const,
} as const;

export default pathBuilders;
