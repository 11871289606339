import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import useLocalStorageState from "./useLocalStorageState";
import useRequiredParams from "./useRequiredParams";
import { useGetCase } from "../../api/endpoints/cases/caseGetApi";
import { caseRouteParamsSchema } from "../../pages/case-default/CaseIndexPage";
import useDartsStore from "../../store/useDartsStore";

function useFailedTaxCalcsToast() {
  const isTaxCalcsFailedToastShown = useRef<boolean>(false);
  const { caseId } = useRequiredParams(caseRouteParamsSchema);
  const { data: caseData } = useGetCase(caseId);
  const { t } = useTranslation("case");
  const { formatDateTime } = useCosmicLocalizationContext();
  const addToast = useDartsStore((state) => state.addToast);

  const [taxCalcsFailedToastDismissed, setTaxCalcsFailedToastDismissed] =
    useLocalStorageState<string[]>("tb.darts.taxCalcsFailedToastDismissed", []);

  useEffect(() => {
    if (caseData?.calcsFailedTimestamp && !isTaxCalcsFailedToastShown.current) {
      const failedKey = `${caseData.id}_${caseData.calcsFailedTimestamp}`;

      if (!taxCalcsFailedToastDismissed.includes(failedKey)) {
        isTaxCalcsFailedToastShown.current = true;

        addToast({
          content: t("LastTaxCalcFailed", {
            timestamp: formatDateTime({
              date: caseData.calcsFailedTimestamp,
              format: "DateTime",
            }),
          }),
          variant: "danger",
          autoHide: false,
          onClose: () => {
            setTaxCalcsFailedToastDismissed([
              ...taxCalcsFailedToastDismissed,
              failedKey,
            ]);
            isTaxCalcsFailedToastShown.current = false;
          },
        });
      }
    }
  }, [
    caseData,
    addToast,
    taxCalcsFailedToastDismissed,
    setTaxCalcsFailedToastDismissed,
    formatDateTime,
    t,
  ]);
}

export default useFailedTaxCalcsToast;
